import React from 'react';
import ImagesProvider from '@/contexts/images';
import { Seo } from '@/components';
import { Root } from '@/layouts';
import { Funcionalidade, Hero, Numbers, Duvidas, Aplicativo, Novidades } from '@/layouts/Home';
import funcionalidades from '@/layouts/Home/Funcionalidade/constants';

const schema = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Home',
    },
  ],
};

const Home = () => {
  return (
    <ImagesProvider>
      <Seo
        description="O melhor software odontológico de toda a América Latina. Dentista, junte-se ao Simples Dental e mais de 60 mil usuários."
        schema={schema}
      />
      <Root variant="dark" hero={<Hero />}>
        <Novidades />
        {funcionalidades.map(f => (
          <Funcionalidade funcionalidade={f} key={f.id} />
        ))}
        <Numbers />
        <Duvidas />
        <Aplicativo />
      </Root>
    </ImagesProvider>
  );
};

export default Home;
